<template>
	<div class="depart-box">
		<div class="left-box">
			<div class="title-box">
				<span class="title">分组</span>
				<div class="btn-box">
					<el-tooltip effect="dark" content="编辑" placement="bottom">
						<img class="btn-img" @click="addDepartment('edit')" v-if="perms.includes('sys:dept:update')" src="@/assets/images/bianji.png" alt="">
					</el-tooltip>
					<el-tooltip effect="dark" content="新增" placement="bottom">
						<img class="btn-img" @click="addDepartment('add')" v-if="perms.includes('sys:dept:add')" src="@/assets/images/xinzeng.png" alt="">
					</el-tooltip>
					<el-tooltip effect="dark" content="删除" placement="bottom">
						<img class="btn-img" @click="handleDeleteDepart()" v-if="perms.includes('sys:dept:delete')" src="@/assets/images/shanchu.png" alt="">
					</el-tooltip>
				</div>
			</div>
			<div :style="height" class="tree-box">
				<el-tree
					:data="treeData"
					:props="defaultProps"
					:default-expand-all="true"
					:expand-on-click-node="false"
					@node-click="handleNodeClick">
					<span class="custom-tree-node" slot-scope="{ node, data }">
						<el-tooltip class="item" effect="light" :content="node.label" placement="right">
							<span class="tree-tips">{{node.label}}</span>
						</el-tooltip>
					</span>
				</el-tree>
			</div>
		</div>
		<div class="right-box">
			<div class="top-box">
				<div class="top-left-box">
					<label class="label">关键字</label>
					<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字"></el-input>
					<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="handleSearchUser">
					<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
				</div>
				<div class="top-right-box">
					<button class="template-btn" v-if="perms.includes('sys:dept:import')" @click="handleTemplate">模板下载</button>
					<button class="btn-blue" @click="handleImport" v-if="perms.includes('sys:dept:import')">导入</button>
					<input style="display:none;" ref="uploadExcel" type="file" id="uploadExcel" @change="uploadHandle($event)">
					<button class="btn-blue" @click="handleExport" v-if="perms.includes('sys:dept:export')">导出</button>
					<button class="btn-blue" @click="handleAddUser()" v-if="perms.includes('sys:dept:addUser')">新增</button>
					<button class="btn-red" @click="handleDeleteDepartUser()" v-if="perms.includes('sys:dept:rmUser')">移除</button>
					<button class="btn-red" @click="handleClearMember()" v-if="perms.includes('sys:dept:rmUser')">清空通讯组</button>
				</div>
			</div>
			<div class="table-box">
				<el-table
					:data="userList"
					:row-class-name="tableRowClassName"
					:header-cell-style="{color:'#444'}"
					border
					@selection-change="handleSelectionChange"
					style="width: 100%">
					<el-table-column
						align="center"
						type="selection"
						width="55">
					</el-table-column>
					<el-table-column
						prop="name"
						show-overflow-tooltip
						label="姓名">
					</el-table-column>
					<el-table-column
						prop="tel"
						show-overflow-tooltip
						label="手机号">
					</el-table-column>
					<el-table-column
						prop="roleNames"
						show-overflow-tooltip
						label="角色名称">
					</el-table-column>
					<el-table-column
						prop="areaName"
						show-overflow-tooltip
						label="区域">
					</el-table-column>
					<el-table-column
						label="状态"
						align="center"
						width="50">
						<template slot-scope="scope">
							<!-- <span>{{scope.row.active == 1 ? 'Y' : 'N'}}</span> -->
							<span v-if="scope.row.active == 1">Y</span>
						   	<span v-if="scope.row.active == 0" style="color:red;">N</span>
						</template>
					</el-table-column>
					<el-table-column
						prop="agentName"
						show-overflow-tooltip
						label="公司名称">
					</el-table-column>
				</el-table>
				<div class="pagination-wrapper pa25" v-if="userList.length > 0">
					<Pagination
						:page.sync="pageNo"
						:pageSize.sync="pageSize"
						:total="total"
						@sizeChange="handleSizeChange"
						@currentChange="handleCurrentChange">
					</Pagination>
				</div>
			</div>
		</div>
		<edit-data ref="editData" @getDepartTree="getDepartTree"></edit-data>
		<edit-user ref="editUser" @getDepartUserList="getDepartUserList"></edit-user>
		<el-dialog
			:visible.sync="visible"
			:show-close="false"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			class="role-dialog"
			width="450px">
			<span slot="title" class="dialog-title">
				<span>提示</span>
				<img src="@/assets/images/close.png" alt="" @click="visible = false">
			</span>
			<ul class="error-list">
				<li v-for="(item,i) in errorList" :key="i">{{item}}</li>
			</ul>
		</el-dialog>
	</div>
</template>
<script>
import EditData from './components/edit-data'
import EditUser from './components/edit-user'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditData,
		EditUser,
		Pagination
	},
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			searchKey: '',
			pageNo: 1,
			pageSize: 20,
			total: 0,
			// 分组下的人员
			userList: [],
			// 分组树结构
			treeData: [],
			// 点击选择的当前节点数据
			currentObj: {},
			checkboxList: [],
			defaultProps: {
				children: 'children',
				label: 'name'
			},
			visible: false,
			errorList: [],
			height: ''
		}
	},
	created() {
		this.getDepartTree();
	},
	mounted() {
		this.height = 'height:' + (window.innerHeight - 180) + 'px;'
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({ row, rowIndex }) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 获取组织树
		getDepartTree() {
			let that = this;
			that.$request.get(
				"sysDepartTree",
				true,
				{},
				function (r) {
					if (r.code == "0") {
						that.treeData = r.data;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 点击节点（部门）
		handleNodeClick(data,node,el) {
			this.currentObj = data;
			this.getDepartUserList(data);
		},
		// 重置查询条件
		handleReset() {
			this.pageNo = 1;
			this.searchKey = '';
			this.getDepartUserList(this.currentObj);
		},
		// 获取部门用户
		getDepartUserList(data) {
			let that = this;
			that.$request.get(
				"sysDepatUserList",
				true,
				{
					searchKey: that.searchKey,
					deptId: data.id,
					pageNo: that.pageNo,
					pageSize: that.pageSize
				},
				function (r) {
					if (r.code == "0") {
						that.userList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 搜索用户
		handleSearchUser() {
			this.pageNo = 1;
			this.getDepartUserList(this.currentObj);
		},
		// 新增部门
		addDepartment(type) {
			if(type == 'edit' && JSON.stringify(this.currentObj) == '{}'){
				this.$message.error('请选择需要编辑的分组')
				return
			}
			this.$refs.editData.init(type,this.currentObj);
		},
		// 删除部门
		handleDeleteDepart() {
			if(JSON.stringify(this.currentObj) == '{}'){
				this.$message.error('请选择需要删除的分组')
				return
			}
			this.$confirm('是否删除当前分组?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'sysDepatDelete',
					true,
					{
						id: that.currentObj.id
					},
					function (r) {
						if (r.code == "0") {
							that.userList = [];
							that.currentObj = {};
							that.getDepartTree();
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		// 新增用户
		handleAddUser() {
			if(JSON.stringify(this.currentObj) == '{}'){
				this.$message.error('请选择分组')
				return
			}
			this.$refs.editUser.init(this.currentObj);
		},
		// 多选
		handleSelectionChange(val) {
			this.checkboxList = val;
		},
		// 删除分配用户
		handleDeleteDepartUser() {
			if(this.checkboxList.length == 0) {
				this.$message.error('请选择用户')
				return
			}
			let userIdList = [];
			this.checkboxList.forEach(item => {
				userIdList.push(item.id);
			})
			this.$confirm('是否移除选择的用户?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'sysDepatDeleteUser',
					true,
					{
						deptId: that.currentObj.id,
						userIdList: userIdList
					},
					function (r) {
						if (r.code == "0") {
							that.getDepartUserList(that.currentObj);
							that.$message.success('移除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		handleClearMember() {
			if(JSON.stringify(this.currentObj) == '{}'){
				this.$message.error('请选择分组')
				return
			}
			this.$confirm('是否清空选择的通讯组成员?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'clearMember',
					true,
					{
						deptId: that.currentObj.id
					},
					function (r) {
						if (r.code == "0") {
							that.getDepartUserList(that.currentObj);
							that.$message.success('清除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		handleSizeChange() {
			this.getDepartUserList(this.currentObj);
		},
		handleCurrentChange() {
			this.getDepartUserList(this.currentObj);
		},
		// 导入
		handleImport() {
			if(JSON.stringify(this.currentObj) == '{}'){
				this.$message.error('请选择分组')
				return
			}
			let el = document.getElementById('uploadExcel')
            el.click();
		},
		// 上传
		uploadHandle(e) {
			let file = e.target.files[0];
            if(file){
				if(window.sessionStorage.getItem('userInfo').superAdminFlag == '1' && file.size/1024/1024/1024 > 1) {
					this.$message.error('导入文件不能大于1GB');
					return
				}
				// 2022-07-31 应客户要求改为200M
				if(window.sessionStorage.getItem('userInfo').superAdminFlag != '1' && file.size/1024/1024 > 200) {
					this.$message.error('导入文件不能大于200MB');
					return
				}
				if(file.name.includes('.xlsx')){
					let formdata = new FormData();
					formdata.append('file',file);
					formdata.append('deptId',this.currentObj.id);
					let that = this;
					that.$request.initUploadExcel(
						formdata,
						'sysDepUpload',
						true
					)
					.then((data) => {
						if(data.code == 0) {
							that.pageNo = 1;
							that.getDepartUserList(that.currentObj);
							if(data.data.length > 0) {
								that.visible = true;
								that.errorList = data.data;
							}else {
								that.$message.success('导入成功');
							}
						}
					})
				}else {
					this.$message.error('文件格式不正确');
				}
			}else{
				this.$message.error('请选择上传的文件');
			}
			this.$refs.uploadExcel.value = '';
		},
		// 导出
		handleExport() {
			if(JSON.stringify(this.currentObj) == '{}'){
				this.$message.error('请选择分组')
				return
			}
			let that = this
			that.$request.get(
				"sysDepDownload",
				true,
				{
					searchKey: that.searchKey,
					deptId: that.currentObj.id
				},
				function (res) {
					if (res.code == '0') {
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = res.data;
						document.body.appendChild(linkNode);
						linkNode.click();  //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);
					} else {
						that.$message.error(res.msg)
					}
				}
			)
		},
		// 模板下载
		handleTemplate() {
			const linkNode = document.createElement('a');
			linkNode.style.display = 'none';
			linkNode.href = '/static/template/GROUP-USER-DATA.xlsx';
			linkNode.download = 'GROUP-USER-DATA.xlsx';
			document.body.appendChild(linkNode);
			linkNode.click();  //模拟在按钮上的一次鼠标单击
			URL.revokeObjectURL(linkNode.href); // 释放URL 对象
			document.body.removeChild(linkNode);
		}
	},

}

</script>
<style lang="scss" scoped>
.block{
	display: block;
}
.depart-box {
	display: flex;
	height: 100%;
	.left-box{
		width: 180px;
		box-shadow: 4px 1px 13px 2px rgba(193, 193, 193, 0.23);
		.title-box{
			border-bottom: 1px solid rgba(210, 210, 210, 1);
			line-height: 60px;

			box-sizing: border-box;
			padding: 0 10px;
			.title{
				font-size: 22px;
				color: #444;
				font-weight: bold;
			}
			.btn-box{
				float: right;
				margin-top: 10px;
			}
			.btn-img{
				width: 32px;
				height: 32px;
				cursor: pointer;
			}
		}
	}
	.right-box{
		flex: 1;
		box-sizing: border-box;
		padding: 15px;
		.top-box{
			height: 32px;
			.top-left-box{
				float: left;
				.label{
					font-size: 15px;
					margin-right: 5px;
				}
				.search-input{
					width: 138px;
					margin: 0 10px;
				}
				.search-btn{
					width: 32px;
					vertical-align: middle;
					cursor: pointer;
				}
				.refresh-btn{
					width: 32px;
					height: 32px;
					border: none;
					color: #117FFC;
					padding: 0;
					background-color: rgba(17, 127, 252, 0.2);
					font-weight: bold;
					font-size: 22px;
					vertical-align: bottom;
					margin-left: 5px;
				}
			}
			.top-right-box{
				float: right;
			}
			.btn-blue{
				display: inline-block;
				width: 66px;
				height: 32px;
				line-height: 32px;
				border: none;
				background-color: #117FFC;
				border-radius: 4px;
				color: #fff;
				font-size: 15px;
				text-align: center;
				cursor: pointer;
				margin-right: 10px;
			}
			.template-btn{
				display: inline-block;
				width: 88px;
				height: 32px;
				line-height: 32px;
				border: none;
				background-color: #117FFC;
				border-radius: 4px;
				color: #fff;
				font-size: 15px;
				text-align: center;
				cursor: pointer;
				margin-right: 10px;
			}
			.btn-red{
				display: inline-block;
				// width: 66px;
				height: 32px;
				padding: 0 18px;
				line-height: 32px;
				border: none;
				background-color: rgba(233, 81, 94, 0.2);
				border-radius: 4px;
				color: rgba(233, 81, 94, 1);
				font-size: 15px;
				text-align: center;
				cursor: pointer;
				&:last-child{
					margin-left: 10px;
				}
			}
		}
		.table-box{
			margin-top: 20px;
		}
	}
}
.tree-box{
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}
.tree-tips{
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    width: 140px;
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.error-list{
	height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
	li{
		list-style: none;
	}
}
</style>
<style>
.el-tree-node:focus > .el-tree-node__content {
	background-color:rgba(199, 227, 249, 0.55) !important;
}
.el-tree-node__content:hover {
	background-color:rgba(199, 227, 249, 0.55);
}
.el-tree-node.is-current>.el-tree-node__content {
	background-color: rgba(199, 227, 249, 0.55);
}
</style>
