<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="720px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<div class="user-box">
			<div class="top-box">
				<label class="label">搜索用户</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入姓名"></el-input>
				<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="handleSearch">
			</div>
			<div class="table-box">
				<el-table
					ref="multipleTable"
					:data="userList"
					:row-class-name="tableRowClassName"
					:header-cell-style="{color:'#444'}"
					@selection-change="handleSelectionChange"
					@select-all="handleSelectAll"
					@select="handleSelect"
					style="width: 100%">
					<el-table-column
						align="center"
						type="selection"
						width="55">
					</el-table-column>
					<el-table-column
						prop="name"
						show-overflow-tooltip
						label="姓名">
					</el-table-column>
					<el-table-column
						prop="tel"
						show-overflow-tooltip
						label="手机号">
					</el-table-column>
					<el-table-column
						prop="roleNames"
						show-overflow-tooltip
						label="角色名称">
					</el-table-column>
					<el-table-column
						prop="areaName"
						show-overflow-tooltip
						label="区域">
					</el-table-column>
					<el-table-column
						prop="agentName"
						show-overflow-tooltip
						label="公司名称">
					</el-table-column>
				</el-table>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<Pagination
				:page.sync="pageNo"
				:pageSize.sync="pageSize"
				:total="total"
				@sizeChange="handleSizeChange"
				@currentChange="handleCurrentChange">
			</Pagination>
			<el-button class="submit-btn" @click="submit">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
import Pagination from '@/components/Pagination'
export default {
	components: {
		Pagination
	},
	data() {
		return {
			title: '新增用户',
			visible: false,
			submitUrl: 'sysDepatAddUser',
			currentObj: '',
			searchKey: '',
			userList: [],
			checkboxList: [],
			allCheckList: [],
			pageNo: 1,
			pageSize: 20,
			total: 0,
		}
	},
	methods: {
		init(data) {
			this.visible = true;
			this.$nextTick(() => {
				this.searchKey = '';
				this.pageNo = 1;
				this.currentObj = data;
				this.userList = [];
				this.checkboxList = [];
				this.allCheckList = [];
				this.getUserList();
			})
		},
		// NO.隔行变色
		tableRowClassName({ row, rowIndex }) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 多选
		handleSelectionChange(selection) {
			this.checkboxList = selection;
		},
		// 全选
		handleSelectAll(selection) {
			if(selection.length == 0) {
				for (let i = this.userList.length - 1; i >= 0; i--) {
					let item = this.userList[i];
					let index = this.allCheckList.findIndex(ele => ele.id == item.id);
					index == -1 ? '' : this.allCheckList.splice(index,1);
				}
			}
		},
		// 单选
		handleSelect(selection, row) {
			if(selection.indexOf(row) == -1) {
				let index = this.allCheckList.findIndex(ele => ele.id == row.id);
				index == -1 ? '' : this.allCheckList.splice(index,1);
			}
		},
		// 获取用户数据
		getUserList() {
			let that = this;
			that.$request.get(
				"allExcludeDept",
				false,
				{
					searchKey: that.searchKey,
					excludeDeptId: that.currentObj.id,
					pageSize: that.pageSize,
					pageNo: that.pageNo,
					active: '1'
				},
				function (r) {
					if (r.code == "0") {
						that.userList = r.data.list;
						that.total = r.data.totalCount;
						if(that.allCheckList.length == 0) {
							return
						}
						setTimeout(() => {
							that.userList.forEach(item => {
								that.allCheckList.findIndex(ele => ele.id == item.id) == -1 ? '' : that.$refs.multipleTable.toggleRowSelection(item,true);
							})
						}, 300);
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleSizeChange() {
			this.allCheckList = this.allCheckList.concat(this.checkboxList);
			this.getUserList();
		},
		handleCurrentChange() {
			this.allCheckList = this.allCheckList.concat(this.checkboxList);
			this.getUserList();
		},
		handleSearch() {
			this.allCheckList = this.allCheckList.concat(this.checkboxList);
			this.pageNo = 1;
			this.getUserList();
		},
		// 获取用户列表
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			this.allCheckList = this.allCheckList.concat(this.checkboxList);
			if(this.allCheckList.length == 0) {
				this.$message.error('请选择需要添加的用户');
				return
			}
			let userIdList = [];
			this.allCheckList.forEach(item => {
				userIdList.push(item.id);
			})
			let that = this;
			that.$request.post(
				that.submitUrl,
				true,
				{
					deptId: that.currentObj.id,
					userIdList: userIdList
				},
				function (r) {
					if (r.code == "0") {
						that.visible = false;
						that.$emit('getDepartUserList',that.currentObj);
						that.$message.success('保存成功');
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},1000)
	}
}
</script>
<style lang="scss" scoped>
.block{
	display: block;
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	padding: 0 !important;
	line-height: 24px !important;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 0px 20px 40px !important;
}
.top-box{
	float: right;
	margin-bottom: 15px;
	.label{
		font-size: 15px;
		margin-right: 5px;
	}
	.search-input{
		width: 138px;
		margin: 0 10px;
	}
	.search-btn{
		width: 32px;
		vertical-align: middle;
		cursor: pointer;
	}
}
.table-box{
	height: 55vh;
    overflow: auto;
    width: 100%;
}
.pagination-container{
	float: left;
}
</style>
